@import url('https://fonts.googleapis.com/css?family=Inter:200,300,400,500,600,700,800&display=swap');

$mw: 1440px; // Максимальная ширина контента
$minw: 320px;  // Минимальная ширина контента

$hp: 80px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px

html {
  appearance: none;
}

body {
  min-width: $minw;
  margin: 0;
  background: #edece3 url(./assets/background-desktop.png) left top no-repeat;

  & .modal-window {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 5;
    background-color: rgba(0,0,0,0.2);
    cursor: pointer;
  }
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

header {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 5;
  height: 42px;
  padding: 35px calc(#{$hm} + #{$hp}) 25px calc(#{$hm} + #{$hp});
  background-color: #323644;
}

.searchHeader {
  // background: #f0f0f0;
}

.staticHeader {
  background-color: #EDECE3;
}

.static-page {
  padding: 0 calc(#{$hm} + #{$hp});
  background-color: #EDECE3;
}

.fleet-page {
  padding: 0 calc(#{$hm} + #{$hp});
  background-color: #EDECE3;
}

.flights {
  background: #f0f0f0;
}

.flightinfo {
  background: #f0f0f0;
  border-top: 1px solid rgba(0,0,1,0.1);
}


.firstscreen {
  position: relative;
  padding: 35px calc(calc((100% - 1440px) / 2) + 80px) 25px calc(calc((100% - 1440px) / 2) + 80px);

  @media (min-width: 701px) {
    //padding-top: 250px !important;
  }

  @media (min-width: 1000px) {
    //padding-top: 300px !important;
  }

  & > * {
    position: relative;
    z-index: 3;
  }

  video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.quote {
  padding: 80px calc(#{$hm} + #{$hp}) 0 calc(#{$hm} + #{$hp});
}

.bblock {
  display: flex;
  margin-top: 80px;
  padding: 80px calc(#{$hm} + #{$hp}) 387px calc(#{$hm} + #{$hp});
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: url(./assets/jet-bblock.png) center bottom/auto 367px no-repeat,
  #141414 url(./assets/bblock-back.png) center bottom/100% 367px no-repeat;
}

.seo-bblock {
  display: flex;
  margin-top: 80px;
  margin-bottom: -20px;
  padding: 80px calc(#{$hm} + #{$hp}) 100px calc(#{$hm} + #{$hp});
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: #141414;
}

.seo-spec-items {
  padding: 80px calc(#{$hm} + #{$hp}) 100px calc(#{$hm} + #{$hp});
  margin-bottom: -20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: #EDECE3;
}

.seo-faq {
  padding: 80px calc(#{$hm} + #{$hp}) 100px calc(#{$hm} + #{$hp});
  margin-bottom: -20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: #F4F4ED;
}

.seo-search-results {
  padding: 80px calc(#{$hm} + #{$hp}) 100px calc(#{$hm} + #{$hp});
  margin-bottom: -20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: #EDECE3;
}

.seo-jet {
  padding: 80px calc(#{$hm} + #{$hp}) 100px calc(#{$hm} + #{$hp});
  margin-bottom: -20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: #F4F4ED;
}

.second_screen {
  padding: 100px calc(#{$hm} + #{$hp}) 0 calc(#{$hm} + #{$hp});
  background: url(./assets/secondscreen-back-desktop.png) left -90px no-repeat;
}

footer {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.96) 58.86%, rgba(255, 255, 255, 0) 94.14%);
  backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 50px calc(#{$hm} + #{$hp}) 40px calc(#{$hm} + #{$hp});
}

@media (max-width: $mw) {

  header {
    padding: 35px $hp 25px $hp;
  }

  .static-page {
    padding: 0 $hp;
  }

  .fleet-page {
    padding: 0 $hp;
  }

  .firstscreen {
    padding: 20px $hp 20px $hp;
  }

  .quote {
    padding: 80px $hp 0 $hp;
  }

  .bblock {
    padding: 80px $hp 387px $hp;
  }

  .seo-bblock {
    padding: 80px $hp 100px $hp;
  }

  .seo-spec-items {
    padding: 80px $hp 100px $hp;
  }

  .seo-faq {
    padding: 80px $hp 100px $hp;
  }

  .seo-search-results {
    padding: 80px $hp 100px $hp;
  }

  .seo-jet {
    padding: 80px $hp 100px $hp;
  }

  .second_screen {
    padding: 60px $hp 0 $hp;
  }

  footer {
    padding: 50px $hp 40px $hp;
  }
}

@media (max-width: 1300px) {

  header {
    padding: 35px $part-hm 25px $part-hm;
  }

  .static-page {
    padding: 0 $part-hm;
  }

  .fleet-page {
    padding: 0 $part-hm;
  }

  .firstscreen {
    padding: 20px $part-hm 20px $part-hm;
  }

  .quote {
    padding: 80px $part-hm 0 $part-hm;
  }

  .bblock {
    padding: 80px $part-hm 387px $part-hm;
  }

  .seo-bblock {
    padding: 80px $part-hm 100px $part-hm;
  }

  .seo-spec-items {
    padding: 80px $part-hm 100px $part-hm;
  }
  .seo-faq {
    padding: 80px $part-hm 100px $part-hm;
  }

  .seo-search-results {
    padding: 80px $part-hm 100px $part-hm;
  }

  .seo-jet {
    padding: 80px $part-hm 100px $part-hm;
  }

  .second_screen {
    padding: 60px $part-hm 0 $part-hm;
  }

  footer {
    padding: 50px $part-hm 40px $part-hm;
  }
}

@media (max-width: 700px) {

  body {
    background: #edece3 url(./assets/background-mobile.png) left top/100% 540px no-repeat;
  }

  header {
    padding: 10px $mobi-hm 10px $mobi-hm;
    height: 34px;
  }

  .static-page {
    padding: 0 $mobi-hm;
  }

  .fleet-page {
    padding: 0 $mobi-hm;
  }

  .searchHeader {
    // background: #FFFFFF;
    padding-bottom: 10px;
  }

  .flights .shadow_box {
      box-shadow: 0 0 10px rgba(140, 54, 0, 0.13);
  }

  .firstscreen {
    // margin-top: 10px;
    padding: 15px $mobi-hm 0 $mobi-hm;
  }

  .quote {
    padding: 20px $mobi-hm+5 35px $mobi-hm+5;
    background-color: #FCF8F2;
  }

  .bblock {
    display: block;
    background: #141414 url(./assets/jet-bblock.png) center bottom/auto 233px no-repeat;
    margin-top: -15px;
    padding: 30px $mobi-hm+20 263px $mobi-hm+20;
  }

  .seo-bblock {
    flex-wrap: wrap-reverse;
    margin-top: 0;
    padding: 30px $mobi-hm+20 45px $mobi-hm+20;
    margin-bottom: 0;
  }

  .seo-spec-items {
    padding: 30px $mobi-hm+20 45px $mobi-hm+20;
  }

  .seo-faq {
    padding: 30px $mobi-hm+20 60px $mobi-hm+20;
  }

  .seo-search-results {
    padding: 30px $mobi-hm+20 60px $mobi-hm+20;
  }

  .seo-jet {
    padding: 30px $mobi-hm+20 60px $mobi-hm+20;
  }

  .second_screen {
    background: url(./assets/secondscreen-back-mobile.png) left top/100% 100% no-repeat;
    padding: 40px $mobi-hm+20 0 $mobi-hm+20;
  }

  footer {
    background: linear-gradient(360deg, #EDECE3 2.39%, rgba(237, 236, 227, 0) 15.57%), linear-gradient(180deg, #EDECE3 1.56%, rgba(237, 236, 227, 0) 15.32%), #FFFFFF;
    backdrop-filter: none;
    border-radius: 0;
    margin-top: 60px;
    padding: 0 $mobi-hm+20 40px $mobi-hm+20;
  }
}
