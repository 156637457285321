.firstscreen .items .search {

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 16px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font: 600 20px/22px Inter;
    letter-spacing: -0.06em;

    & .icon_mobile {
      display: none;
      margin-right: 14px;
    }
  }

  & .form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & .form-items {
      display: flex;
      flex-grow: 1;
    }

    & .where,
    & .to,
    & .date {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 33.33%;
      flex-shrink: 0;
      cursor: pointer;
      color: #28238F;
    }

    & .where,
    & .to {
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }

    & .form-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      margin-top: auto;
    }

    & .pax-button-item {
      background: #FAF7F2;

      & .pax-button-tilde {
        background: #fff;
      }
    }

    & .form-submit {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 183px;
      min-height: 42px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.02em;
      color: #000;
      background: none;
      border: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &::before {
        content: '';
        position: absolute;
        background-color: #0085b3;
        border-radius: 8px;
        transform: skewX(-3deg);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      span {
        position: relative;
      }
    }

    & .caption {
      font: 600 20px/22px Inter;
      letter-spacing: -0.02em;
      margin: 13px 12px 0;
    }

    & .caption_selected {
      margin: 10px 20px 0px 20px;
      font: 600 14px/14px Inter;
    }

    & .action {
      max-width: 180px;
      font: 500 32px/32px Inter;
      letter-spacing: -0.04em;
      color: #0085b3;
      margin: auto 12px 22px;
    }

    & .airport_selected {
      padding: 17px 0 17px 20px;

      & .container {
        overflow: hidden;
        margin-bottom: 7px;

        & .name {
          font: 21px/22px Inter;
          letter-spacing: -0.04em;
          color: #000;
        }

        & .location {
          margin-top: 4px;
          font: 14px/14px Inter;
          letter-spacing: -0.02em;
          color: #000;
        }
      }

      & .iata,
      & .icao {
        font: 400 14px/20px Inter;
        letter-spacing: -0.02em;
        color: #0085b3;
      }
    }

    & .selected-date {
      font: 600 21px/21px Inter;
      padding: 17px 0 17px 20px;

      & .selected-date-date {
        letter-spacing: -0.02em;
      }

      & .selected-date-time {
        margin-top: 6px;
        font-weight: 400;
        color: #7D7B79;
      }
    }
  }
}

@media (max-width: 700px) {

  .firstscreen .items .search {
    flex-direction: column;
    background-color: #fff;
    border-radius: 25px 25px 0 0 ;

    & .form {
      width: 100%;

      & .date-content {
        display: none;
      }

      & .form-footer {
        flex-direction: column;
        align-items: flex-start;
        padding: 22px 16px;
        gap: 16px;
      }

      & .form-submit {
        width: 100%;
      }
    }

    & .header {
      justify-content: flex-start;
      padding: 18px 19px 14px 20px;
      font: 600 14px/14px Inter;
      letter-spacing: -0.02em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      & .icon_mobile {
        display: block;
      }

      & .icon_desktop {
        display: none;
      }
    }

    & .search-heading-mob {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.02em;
      padding: 16px 16px 12px;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    & .form .form-items {
      flex-wrap: wrap;
      width: 100%;

      & .where,
      & .to {
        width: 50%;
      }

      & .to {
        border-right: none;
      }

      & .date {
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.05);

        .caption_selected {
          font: 600 21px/22px Inter;
          margin: 0;
          padding: 22px 16px 12px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
      }
    }

    & .form .caption {
      margin: 12px 16px 69px 16px;
      font: 600 21px/22px Inter;
    }

    & .form .action {
      width: 115px;
      margin: 0px 16px 12px 16px;
      font: 500 21px/22px Inter;
      color: #0085b3;
    }

    & .datapicker {
      width: 100%;
    }
  }
}

@media (min-width: 701px) {
  .firstscreen .items .search {

    & .search-heading-mob {
      display: none;
    }
  }
}
